interface CountryJSON {
  cca2: string;
  idd: string;
  name: string;
}

export async function useCountryData(): Promise<Array<CountryJSON>> {
  const langCookie = useCookie(GOOTRANS_COOKIE);

  let countries: Array<CountryJSON>;

  try {
    countries = await import(`~~/src/public/assets/country/country-${langCookie.value?.slice(-2) || 'nl'}.json`)
      .then((json) => json.default);
  } catch {
    countries = await import('~~/src/public/assets/country/country-nl.json')
      .then((json) => json.default);
  }

  return countries;
}
