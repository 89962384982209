<script setup lang="ts">
import { DateFormatter, type ZonedDateTime } from '@internationalized/date';

const date = defineModel<ZonedDateTime>('date', { required: true });
const show = defineModel<boolean>('show');
const optionUnit = ref('month');
const initialYear = ref(date.value.year);

function changeDisplayedOption(action: 'add' | 'reduce') {
  const factor = action === 'add' ? 1 : -1;
  if (optionUnit.value === 'month') {
    date.value = date.value.add({ years: factor });
  } else if (optionUnit.value === 'year') {
    initialYear.value += factor * 12;
  }
}

function changeYear(year: number) {
  date.value = date.value.set({ year: initialYear.value + year });
  optionUnit.value = 'month';
}
</script>

<template>
  <div
    v-if="show"
    class="bg-white border border-neutral-400/20 absolute w-50 md:w-64 top-11 left-0 shadow-[2px_3px_16px_1px_rgba(160,171,192,.3)] md:shadow-[2px_3px_16px_1px_rgba(160,171,192,.14)]"
  >
    <div class="flex justify-between items-center text-sm p-4">
      <i
        class="i-tban-chevron -rotate-180 cursor-pointer"
        @click="() => changeDisplayedOption('reduce')"
      />
      <span
        class="text-desktop-p-4 px-4 mt-1 cursor-pointer"
        @click="optionUnit = optionUnit === 'year' ? 'month' : 'year'"
      >
        {{ date.year }}
      </span>
      <i
        class="i-tban-chevron cursor-pointer"
        @click="() => changeDisplayedOption('add')"
      />
    </div>

    <div
      v-if="optionUnit === 'month'"
      class="grid grid-cols-4 text-body-sm md:text-body-md"
    >
      <span
        v-for="month in 12"
        :key="month"
        class="h-10 w-10 md:w-14 md:h-14 m-1 flex items-center justify-center rounded-sm cursor-pointer hover:color-primary-600 hover:bg-primary-100 transition-all-150"
        :class="{ 'bg-primary-100': date.month === month }"
        @click="date = date.set({ month })"
      >
        {{ new DateFormatter('en-GB', { month: 'short' }).format(new Date(1, month - 1)) }}
      </span>
    </div>

    <div
      v-if="optionUnit === 'year'"
      class="grid grid-cols-4 text-body-sm md:text-body-md"
    >
      <span
        v-for="year in 12"
        :key="year"
        class="h-10 w-10 md:w-14 md:h-14 m-1 flex items-center justify-center rounded-sm cursor-pointer hover:color-primary-600 hover:bg-primary-100 transition-all-150"
        :class="{ 'bg-primary-100': date.year === initialYear + year - 1 }"
        @click="() => changeYear(year - 1)"
      >
        {{ initialYear + year - 1 }}
      </span>
    </div>
  </div>
</template>
