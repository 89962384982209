<script setup lang="ts">
import type { BaseBlockAttributes, ImageAttributes } from '~/types';

interface CanvasCaptionProps extends BaseBlockAttributes {
  caption: string;
  image: ImageAttributes;
  textPosition: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
}

defineProps<CanvasCaptionProps>();

const textClass = {
  'top-left': {
    container: 'from-primary-400 to-transparent',
    text: '',
  },
  'bottom-left': {
    container: 'from-transparent to-primary-400',
    text: 'items-end',
  },
  'top-right': {
    container: 'from-primary-400 to-transparent',
    text: 'justify-end text-right',
  },
  'bottom-right': {
    container: 'from-transparent to-primary-400',
    text: 'items-end text-right justify-end',
  },
};
</script>

<template>
  <section
    class="relative bg-cover max-h-180 bg-top"
    :style="{ backgroundImage: `url(${image?.url})`, height: `${image?.sizes.full?.height}px` }"
  >
    <div
      class="absolute w-full h-full top-0 left-0"
      :class="textClass[textPosition].container"
    >
      <div
        class="max-w-page mx-auto text-body-md font-medium color-white h-full flex p-6 md:text-h5-base md:py-18 xl:px-0"
        :class="textClass[textPosition].text"
        v-html="caption"
      />
    </div>
  </section>
</template>
