<script lang="ts" setup>
interface RichTextProps {
  color?: 'dark' | 'light';
  content: string;
}

const props = defineProps<RichTextProps>();

const baseColor = props.color === 'light' ? '#E6E3E3' : '#574545';
const highlightColor = props.color === 'light' ? '#FFFFFF' : '#009BAC';
const headingColor = props.color === 'light' ? '#FFFFFF' : '#E50071';

const processedContent = computed(() => {
  // This regex finds blocks of text that start with [caption and end with [/caption], indicating a WordPress caption shortcode.
  // The '+' after the pattern means it will match one or more consecutive instances of this pattern.
  let contentWithCaptionsWrapped = props.content.replace(
    /((?:\[caption id="attachment_[^\"]+" align="[^"]+"[^\]]*\].*?\[\/caption\]\s*)+)/g,
    // Wrap matched [caption] blocks in a 'flex-container' DIV for layout purposes.
    '<div class="flex-container">$1</div>',
  );

  // This regex looks for paragraphs that directly contain an <img> tag.
  // The '([^"]*)' captures the class attribute of the <img> tag, which contains alignment info (like alignleft or aligncenter).
  contentWithCaptionsWrapped = contentWithCaptionsWrapped.replace(
    /<p>\s*<img class="([^"]*)"([^>]+)>\s*<\/p>/g,
    // For each match, it wraps the <img> in a <figure> and the whole in a 'flex-container' DIV.
    // The alignment class from the <img> is extracted and applied to the <figure> for layout.
    (match, imgClass, imgAttributes) => {
      const alignmentClass = imgClass.match(/align\w+/)[0]; // Extract only the alignment-related class (e.g., alignleft, aligncenter)
      return `<div class="flex-container"><figure class="flex ${alignmentClass}"><img class="${imgClass}"${imgAttributes}></img></figure></div>`;
    },
  );

  // This regex is for handling paragraphs that contain two or more <img> tags. The '{2,}' part means match two or more instances of the preceding pattern.
  // This is used to detect consecutive images that should be wrapped in individual <figure> tags within a single flex-container.
  contentWithCaptionsWrapped = contentWithCaptionsWrapped.replace(
    /<p>(\s*<img class="([^"]*)"([^>]+)>\s*){2,}<\/p>/g,
    (match) => {
      // For each <img> tag in the match, it's wrapped in a <figure> with the alignment class applied.
      const figureWrappedImgs = match.replace(
        /<img class="([^"]*)"([^>]+)>/g,
        (imgMatch, imgClass, imgAttributes) => {
          const alignmentClass = imgClass.match(/align\w+/)[0]; // Again, extract only the alignment-related class.
          return `<figure class="flex ${alignmentClass}"><img class="${imgClass}"${imgAttributes}></img></figure>`;
        },
      );
      return `<div class="flex-container">${figureWrappedImgs}</div>`;
    },
  );

  // This replaces the [caption] shortcode blocks with <figure> elements, keeping the alignment and adding a <figcaption>.
  contentWithCaptionsWrapped = contentWithCaptionsWrapped.replace(
    /\[caption id="attachment_([^\"]+)" align="([^\"]+)"[^\]]*\](<img [^>]*class="([^"]*)"[^>]*>)([^[]*)\[\/caption\]/g,
    (match, id, alignment, imgTag, imgClasses, captionText) => {
      const imgWidth = imgTag.match(/width="(\d+)"/)[1];
      const updatedImgTag = imgTag.replace(`class="${imgClasses}"`, `class="${imgClasses} ${alignment}"`);
      return `<figure class="flex flex-col w-full ${alignment}" >${updatedImgTag}<figcaption style="max-width: ${imgWidth}px;"">${captionText}</figcaption></figure>`;
    },
  );

  return contentWithCaptionsWrapped;
});
</script>

<template>
  <div
    :class="$style['rich-text']"
    v-html="processedContent"
  />
</template>

<style lang="postcss" module>
  /* Headings */
.rich-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply my-2 md:my-4;
    color: v-bind(headingColor);
  }

  h1 {
    @apply text-h1;
  }

  h2 {
    @apply text-h2 color-secondary-400;
  }

  h3 {
    @apply text-h3;
  }

  h4 {
    @apply text-h4 color-secondary-400;
  }

  h5 {
    @apply text-h5;
  }

  h6 {
    @apply text-h6 color-secondary-400;
  }

  /* Text elements */
  p,
  li,
  ol li::marker,
  a,
  mark,
  s,
  em,
  strong,
  blockquote,
  pre,
  sub,
  sup {
    @apply text-body-md leading-normal;
    color: v-bind(baseColor);
  }

  /* Paragraphs */
  p {
    @apply w-full my-3;
  }

  p[style*='text-align: right;'] {
    @apply text-right;
  }

  p[style*='text-align: left;'] {
    @apply text-left;
  }

  p[style*='text-align: center;'] {
    @apply text-center;
  }

  /* Lists */

  ul {
    li {
      @apply pl-8 relative before:content-empty before:i-tban-list-disc before:w-2 before:h-2 before:color-primary before:absolute before:top-2 before:-translate-x-1/2 before:left-4;
    }
  }

  ol {
    li {
      @apply list-decimal ml-5 pl-2;
    }
  }

  ul li::marker {
    @apply text-lg md:text-2xl;
  }

  /* Links */
  a {
    @apply relative hover:underline;
    color: v-bind(highlightColor);
  }

  /* Text formatting */
  em {
    @apply italic;
  }

  strong {
    @apply font-bold;
  }

  em > strong,
  strong > em {
    @apply italic font-bold;
  }

  /* Subscript and Superscript */
  sub,
  sup {
    @apply text-xs;
    color: v-bind(baseColor);
  }

  /* Images */
  img {
    @apply mx-auto my-2 mx-2 h-full max-w-full md:h-full  shadow-md;
  }

  p img {
    @apply mr-4;
  }

  /* Flex Container */
  [class~='flex-container'] {
    @apply flex flex-wrap;
  }

  /* Figures */
  [class~='alignleft'],
  [class~='alignright'],
  [class~='aligncenter'],
  [class~='alignnone'] {
    @apply flex items-center justify-center;
  }

  [class~='alignleft'] {
    @apply md:w-auto md:min-w-1/2 float-left justify-start;
  }

  [class~='alignright'] {
    @apply md:w-auto md:min-w-1/2 float-right justify-end;
  }

  [class~='alignnone'] {
    @apply items-center w-fit mx-0;
  }

  figure {
    @apply w-full;
  }

  /* Figcaption */
  figcaption {
    @apply w-auto text-label-reg text-center;
    color: v-bind(baseColor);
  }

  /* Blockquotes */
  blockquote {
    @apply border-l-6 border-primary-400 pl-6 py-2.5 mx-3 sm:mx-15 my-3;
  }

  /* Preformatted text */
  pre {
    @apply overflow-auto whitespace-pre-wrap p-3 md:px-4 rounded bg-base-100;
  }

  /* Horizontal rules */
  hr {
    @apply my-4 border-b border-base-100;
  }
}
</style>
