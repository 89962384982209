<script setup lang="ts">
import { BaseLink } from '#components';

interface BreadcrumbsProps {
  background?: 'blue' | 'white';
  slugs?: Array<string> | string;
}

const props = withDefaults(defineProps<BreadcrumbsProps>(), {
  background: 'white',
});

const route = useRoute();
const slugs = props.slugs || route.params.slug;

const items = (slugs as Array<string>).filter((item) => item !== '');
</script>

<template>
  <nav
    class="py-6"
    :class="{ 'bg-primary-400': background === 'blue' }"
  >
    <div
      class="max-w-page text-body-lg flex items-center gap-2 px-6 xl:px-0"
      :class="{
        'color-primary-200': background === 'blue',
        'color-neutral-200': background === 'white',
      }"
    >
      <BaseLink
        href="/"
        class="font-medium"
        :class="{
          'hover:color-white': background === 'blue',
          'hover:color-primary-400': background === 'white',
        }"
      >
        Startpagina
      </BaseLink>
      <span
        v-for="(item, index) in items"
        :key="item"
        class=" flex items-center gap-2"
      >
        <i class="i-tban-chevron text-sm" />
        <component
          :is="index === items.length - 1 ? 'span' : BaseLink"
          :href="`/${items.slice(0, index + 1).join('/')}`"
          class="capitalize line-clamp-1 font-medium"
          :class="{
            'hover:color-white': background === 'blue',
            'hover:color-primary-400': background === 'white',
            'color-white font-medium': index === items.length - 1 && background === 'blue',
            'color-primary-400 font-medium': index === items.length - 1 && background === 'white',
          }"
        >
          {{ item.split('-').join(' ') }}
        </component>
      </span>
    </div>
  </nav>
</template>
