import { DateFormatter, parseZonedDateTime } from '@internationalized/date';

export function formatHour(strDate: string) {
  const date = parseZonedDateTime(strDate).toDate();
  return new DateFormatter('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h24',
  }).format(date);
}

export function formatDate(strDate: string) {
  const date = parseZonedDateTime(strDate).toDate();
  return new DateFormatter('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  }).format(new Date(date));
}
