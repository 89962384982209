<script setup lang="ts">
import type { BaseBlockAttributes } from '~/types';

interface HeaderBabetteProps extends BaseBlockAttributes {
  backgroundColor?: string;
  color: 'green' | 'pink' | 'white';
  dottedBorder?: boolean;
  mainTitle: string;
}

const props = defineProps<HeaderBabetteProps>();

const colorClass = {
  'color-secondary-400 bg-[url(/assets/backgrounds/bg-rectangle-pink-sm.svg)] md:bg-[url(/assets/backgrounds/bg-rectangle-pink.svg)]': props.color === 'pink',
  'color-primary-400 bg-[url(/assets/backgrounds/bg-rectangle-blue-sm.svg)] md:bg-[url(/assets/backgrounds/bg-rectangle-blue.svg)]': props.color === 'green',
  'color-white bg-[url(/assets/backgrounds/bg-rectangle-white-sm.svg)] md:bg-[url(/assets/backgrounds/bg-rectangle-white.svg)]': props.color === 'white',
};

const backgroundClass = {
  'bg-[url(/assets/backgrounds/bg-rectangle-pink-sm.svg)] md:bg-[url(/assets/backgrounds/bg-rectangle-pink.svg)]': props.color === 'pink',
  'bg-[url(/assets/backgrounds/bg-rectangle-blue-sm.svg)] md:bg-[url(/assets/backgrounds/bg-rectangle-blue.svg)]': props.color === 'green',
  'bg-[url(/assets/backgrounds/bg-rectangle-white-sm.svg)] md:bg-[url(/assets/backgrounds/bg-rectangle-white.svg)]': props.color === 'white',
};
</script>

<template>
  <section :style="{ backgroundColor }">
    <div class="max-w-page mx-auto py-16 px-4 xl:px-0">
      <h2
        class="text-center text-h2 p-10 md:pb-5 md:pt-8 md:px-6.5 bg-[length:100%_100%]"
        :class="[colorClass, dottedBorder && backgroundClass]"
        v-html="mainTitle"
      />
    </div>
  </section>
</template>
