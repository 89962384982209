<script setup lang="ts">
import { ComboboxAnchor, ComboboxContent, ComboboxEmpty, ComboboxInput, ComboboxItem, ComboboxItemIndicator, ComboboxRoot, ComboboxTrigger } from 'radix-vue';

import type { CategoryAttributes } from '~/types';

interface CategoryQuery {
  data: {
    categories: CategoryAttributes;
  };
}

export interface ArticleListCategoryOption {
  id: string;
  name: string;
}

const { data } = await useGQLFetch<CategoryQuery>(`query NewQuery {
  categories {
    nodes {
      name
      id
    }
  }
}`);
const value = defineModel<Array<ArticleListCategoryOption>>('value');

function filterFunction(list: Array<ArticleListCategoryOption>, searchTerm: string) {
  return list.filter((option) => {
    return option.name.toLowerCase().includes(searchTerm.toLowerCase());
  });
}

function deleteValue(option: ArticleListCategoryOption) {
  value.value = value.value?.filter((item) => item.id !== option.id);
}
</script>

<template>
  <ComboboxRoot
    v-slot="{ open }"
    v-model="value"
    multiple
    :filter-function="filterFunction"
  >
    <ComboboxAnchor>
      <p class="text-body-md font-medium">
        Categorieën Filteren
      </p>
      <ComboboxTrigger
        class="relative w-full mt-2 p-3 pr-9.5 h-13 text-body-lg color-neutral-300 border-2 border-neutral-200 min-w-45 text-left flex flex-wrap items-center gap-2 md:max-w-50 overflow-scroll no-scrollbar"
      >
        {{ (value?.length || 0) === 0 ? 'All tags' : '' }}
        <span
          v-for="item in value"
          :key="item.id"
          class="bg-primary-100 px-3 py-0.5 rounded-full color-primary-400 flex items-center gap-2 text-body-sm font-medium inline-block"
        >
          {{ item.name }}
          <i
            class="i-tban-close text-2 cursor-pointer"
            @click="() => deleteValue(item)"
          />
        </span>
        <i
          class="i-tban-chevron text-sm absolute right-3 top-5 transition-all-250"
          :class="open ? '-rotate-90' : 'rotate-90'"
        />
      </ComboboxTrigger>
    </ComboboxAnchor>

    <ComboboxContent
      position="popper"
      class="bg-white shadow-dropdown w-[calc(100vw-32px)] md:w-80"
      align="end"
      :side-offset="12"
    >
      <ComboboxInput
        placeholder="Search Categories"
        class="border-2 border-neutral-200 m-3 text-body-md color-neutral-300 p-2 focus:border-primary-400 outline-none"
      />
      <ComboboxEmpty class="text-xs font-medium text-center py-2" />
      <ComboboxItem
        v-for="option in data?.data.categories.nodes.filter((cat) => !['Article', 'Uncategorized'].includes(cat.name))"
        :key="option.id"
        :value="option"
        class="group py-2.5 px-3 text-body-md flex items-center gap-3 cursor-pointer hover:bg-primary-400 hover:color-white transition-all-150"
      >
        <span
          class="border-neutral-200 w-5 h-5 inline-flex group-hover:border group-hover:border-white"
          :class="{ border: !value?.includes(option) }"
        >
          <ComboboxItemIndicator class="bg-primary-400 w-full h-full inline-flex items-center justify-center">
            <i class="i-tban-checkmark text-xs mb-0.5 color-white" />
          </ComboboxItemIndicator>
        </span>
        {{ option.name }}
      </ComboboxItem>
    </ComboboxContent>
  </ComboboxRoot>
</template>
