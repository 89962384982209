import {
  BlockArticleList,
  BlockArticlePost,
  BlockArticleRecentCarousel,
  BlockArticleRecentGrid,
  BlockBannerDonation,
  BlockBannerHero,
  BlockBannerImage,
  BlockCalendar,
  BlockCanvasCaption,
  BlockCoreDescriptionPanel,
  BlockCrews,
  BlockDiscoveryPathway,
  BlockDividerMountain,
  BlockDividerSky,
  BlockDividerTriangle,
  BlockDonation,
  BlockEventsList,
  BlockEventsSingle,
  BlockForms,
  BlockFourCards,
  BlockHeaderBabette,
  BlockHiddenImpact,
  BlockImageHighlight,
  BlockLongGallery,
  BlockPartners,
  BlockQuotationBanner,
  BlockQuotationList,
  BlockRichText,
  BlockShopCart,
  BlockShopCheckout,
  BlockShopProductSpotlight,
  BlockShopProducts,
  BlockThreeCards,
  BlockVideoThumbnail,
} from '#components';

export const componentsLibrary = {
  'article-list': BlockArticleList,
  'article-post': BlockArticlePost,
  'articles-recent-grid': BlockArticleRecentGrid,
  'articles-recent-carousel': BlockArticleRecentCarousel,
  'banner-donation': BlockBannerDonation,
  'banner-hero': BlockBannerHero,
  'banner-image': BlockBannerImage,
  'calendar': BlockCalendar,
  'canvas-caption': BlockCanvasCaption,
  'cart': BlockShopCart,
  'checkout': BlockShopCheckout,
  'core-description-panel': BlockCoreDescriptionPanel,
  'crews': BlockCrews,
  'discovery-pathway': BlockDiscoveryPathway,
  'divider-mountain': BlockDividerMountain,
  'divider-sky': BlockDividerSky,
  'divider-triangle': BlockDividerTriangle,
  'donation': BlockDonation,
  'event': BlockEventsSingle,
  'events-list': BlockEventsList,
  'form': BlockForms,
  'four-cards': BlockFourCards,
  'header-babette': BlockHeaderBabette,
  'hidden-impact': BlockHiddenImpact,
  'image-highlight': BlockImageHighlight,
  'long-gallery': BlockLongGallery,
  'partners': BlockPartners,
  'product-spotlight': BlockShopProductSpotlight,
  'products': BlockShopProducts,
  'quotation-banner': BlockQuotationBanner,
  'quotation-list': BlockQuotationList,
  'rich-text': BlockRichText,
  'three-cards': BlockThreeCards,
  'video-thumbnail': BlockVideoThumbnail,
};
