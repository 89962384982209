<script setup lang="ts">
const cartStore = useCartStore();

const total = computed(() => {
  let sumPrice = 0;
  let sumQuantity = 0;
  cartStore.products.forEach((item) => {
    sumPrice += Number.parseFloat(item.product.price || '0') * item.quantity;
    sumQuantity += item.quantity;
  });
  return {
    price: sumPrice,
    quantity: sumQuantity,
  };
});

const priceDetail = computed(() => [
  { label: 'Subtotaal', value: total.value.price },
  { label: 'Korting', value: 0 },
]);
</script>

<template>
  <div
    v-for="price in priceDetail"
    :key="price.label"
    class="flex justify-between mt-2"
  >
    <span v-html="price.label" />
    <span class="font-bold">{{ `€${formatNumber(price.value)}` }}</span>
  </div>
  <div class="font-bold flex justify-between mt-2">
    <span>BTW</span>
    <span>Uitgesloten</span>
  </div>
  <div class="flex items-center justify-between mt-12">
    <span>Totaal</span>
    <span class="text-h5">{{ `€${formatNumber(priceDetail.reduce((acc, v) => acc + v.value, 0))}` }}</span>
  </div>
</template>
