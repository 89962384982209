<script setup lang="ts">
import { ComboboxAnchor, ComboboxContent, ComboboxItem, ComboboxItemIndicator, ComboboxRoot, ComboboxTrigger } from 'radix-vue';

const value = defineModel<string>('value');

const options = ['Newest', 'Oldest', 'Title'];
</script>

<template>
  <ComboboxRoot
    v-slot="{ open }"
    v-model="value"
  >
    <ComboboxAnchor>
      <p class="text-body-md font-medium">
        Sorteer Op
      </p>
      <ComboboxTrigger
        class="relative w-full mt-2 p-3 pr-9.5 h-13 text-body-lg color-neutral-300 border-2 border-neutral-200 min-w-45 text-left flex flex-wrap items-center gap-2 md:max-w-50 overflow-scroll no-scrollbar"
      >
        {{ value }}
        <i
          class="i-tban-chevron text-sm absolute right-3 top-5 transition-all-250"
          :class="open ? '-rotate-90' : 'rotate-90'"
        />
      </ComboboxTrigger>
    </ComboboxAnchor>

    <ComboboxContent
      position="popper"
      class="bg-white shadow-dropdown w-[calc(100vw-32px)] md:w-60 z-10 relative"
      align="end"
      :side-offset="12"
    >
      <ComboboxItem
        v-for="option in options"
        :key="option"
        :value="option"
        class="group py-2.5 px-3 text-body-md flex items-center justify-between cursor-pointer hover:bg-primary-400 hover:color-white transition-all-150 data-[state=checked]:bg-primary-400 data-[state=checked]:color-white"
      >
        {{ option }}
        <ComboboxItemIndicator>
          <i class="i-tban-checkmark text-xs mb-0.5 color-white" />
        </ComboboxItemIndicator>
      </ComboboxItem>
    </ComboboxContent>
  </ComboboxRoot>
</template>
