<script setup lang="ts">
import type { CheckoutDataForm } from '~/types';

const props = defineProps<CheckoutDataForm>();

const reviewData = computed(() => {
  const data = [
    { label: 'Voornaam', value: props.firstName, width: 'md:col-span-3' },
    { label: 'Achternaam', value: props.lastName, width: 'md:col-span-3' },
    { label: 'E-mailadres', value: props.email },
    { label: 'Bedrijfsnaam', value: props.company, width: 'md:col-span-4' },
    { label: 'Land/Regio', value: props.country.name, width: 'md:col-span-2' },
    { label: 'Straatnaam', value: props.address1 },
    { label: 'Postcode', value: props.postcode, width: 'col-span-1' },
    { label: 'Stad', value: props.city, width: 'md:col-span-5' },
    { label: 'Telefoonnummer', value: `${props.phone_extIdd} ${props.phone}` },
  ];

  if (props.paymentMethod && props.paymentMethod.id) {
    data.push({ label: 'Betaalmethode', value: props.paymentMethod?.title });
  }

  return data;
});
</script>

<template>
  <div>
    <h2 class="text-h2 color-secondary-400">
      Bestelling Bevestigen
    </h2>
    <div class="mt-6 grid md:grid-cols-6 gap-4 md:gap-y-6">
      <div
        v-for="item in reviewData"
        :key="item.label"
        class="text-body-md"
        :class="item.width || 'md:col-span-6'"
      >
        <p class="font-medium">
          {{ item.label }}
        </p>
        <p class="color-neutral-300 mt-2 min-h-5">
          {{ item.value || '-' }}
        </p>
      </div>
    </div>
  </div>
</template>
