<script lang="ts" setup>
import type { PageBlock } from '~/types';

import { decodeWPBlock } from '~/utils/decode-data';

const url = useRequestURL();
const { query } = useRoute();

const pageData = await usePageFetch(url.pathname, query);

if (!pageData) {
  showError({
    statusCode: 404,
    statusMessage: 'Page Not Found',
    fatal: true,
  });
}

const parsedPageData: Array<PageBlock> = JSON.parse(pageData?.blocksJSON || '[]');

const decodedComponent = parsedPageData.map((item) => {
  let blockAttr = { ...item };
  if (item.blockType?.category === 'reusable' && item.innerBlocks.length > 0) {
    blockAttr = {
      ...item.innerBlocks[0],
      order: item.order,
      postId: item.postId,
    };
  }

  return decodeWPBlock(blockAttr);
});

useSeoMeta({
  title: pageData?.seo.title,
  description: pageData?.seo.description,
  robots: pageData?.seo.robots.join(', '),
  ogLocale: pageData?.seo.openGraph?.locale,
  ogTitle: pageData?.seo.openGraph?.title,
  ogSiteName: pageData?.seo.openGraph?.siteName,
  ogDescription: pageData?.seo.openGraph?.description,
  ogUrl: pageData?.seo.openGraph?.url,
});
</script>

<template>
  <component
    :is="componentsLibrary[block.name.replace('lazyblock/', '') as keyof typeof componentsLibrary]"
    v-for="block in decodedComponent"
    :key="block.attributes.blockUniqueClass"
    v-bind="block.attributes"
    :class="{ 'tban:pb-25 tban:md:pb-40': block.order === decodedComponent.length - 1 }"
  />
</template>
